import React, { useContext, useState, useEffect } from 'react';
import { Button, Form, InputGroup, Col, Row, Container, Modal, ProgressBar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faCartShopping, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { getItemAttributeNameById } from '../functions';
import EditCartModal from '../elements/EditCartModal';
import { checkCouponThunk, clearCoupon } from '../store/coupons';
import { useDispatch, useSelector } from 'react-redux';
import EditFreeItemModal from '../elements/EditFreeItemModal';
import { getFreeItemsThunk } from '../store/freeitems';
import { placeOrderThunkPayInStore } from '../store/order';
import { useNavigate } from 'react-router-dom';
import CheckoutApp from '../elements/StripeCheckoutApp';
import loadingJson from '../assets/json/loading.json';
import Lottie from 'react-lottie-player';
import { Context } from '../context/AppContext';
import Header from '../components/Header';
import { checkIfLunchTime, isStoreOpen, showTimeError } from '../Utilts/helper';
import Swal from 'sweetalert2';


const opetion2 = [
  { value: "online", label: "Online Payment" },
  { value: "in-store", label: "In Store" },
];

const opetion1 = [
  { value: "online", label: "Online Payment" },
]

const CheckoutPage = ({ fee }) => {
  const navigate = useNavigate();
  const { language, restaurantId, changedCart, setChangedCart} = useContext(Context);
  const [promoCode, setPromoCode] = useState('');
  const cart = JSON.parse(localStorage.getItem('cart')) || [];
  const freeItem = JSON.parse(localStorage.getItem(`freeItem`)) || [];
  const [orderMethod, setOrderMethod] = useState('pickup'); // pickup or delivery
  const [cartOpen, setCartOpen] = useState(true); // State to toggle cart items
  const [editCartModal, setEditCartModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [currentUserAddress, setCurrentUserAddress] = useState(null);
  const [addressVerified, setAddressVerified] = useState(false);
  const [addressErrors, setAddressErrors] = useState('');
  const [needDelivery, setNeedDelivery] = useState(false);
  const [checkingCoupon, setCheckingCoupon] = useState(false);
  const [couponError, setCouponError] = useState('');
  const [tip, setTip] = useState(10);
  const [customTip, setCustomTip] = useState(0);
  const [showTipModal, setShowTipModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false); // State for payment modal
  const dispatch = useDispatch();
  const checkCoupon = useSelector(state => state.coupons.coupon);
  const [editFreeItem, setEditFreeItem] = useState(false);
  const freeItems = useSelector((state) => state.freeItems.freeitems);
  const [paymentOption, setPaymentOption] = useState('online');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState(false);
  const [payinStoreLoading, setPayinStoreLoading] = useState(false);  // State for loading spinner
  const [paymentSubmitLoading, setPaymentSubmitLoading] = useState(false);  // State for loading spinner

  useEffect(() => { 
      if(!showPaymentModal){
        setName('');
        setPhone('');
        setError('');
        setActiveTab(false);
      }
  },[showPaymentModal])



  useEffect(() => { 
    if (restaurantId) {
      dispatch(getFreeItemsThunk(restaurantId));
    }
  },[restaurantId, dispatch]);

  useEffect(() => {
    if (needDelivery) {
      handleScriptLoad();
      handleStoreMap();
    } else {
      handleStoreMap();
    }
  }, [needDelivery, currentUserAddress]);

  let autocomplete;
  const handleScriptLoad = () => {
    /*global google*/ // To disable any eslint 'google not defined' errors
    autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("autocomplete"),
      { types: ["geocode"] }
    );

    autocomplete.addListener("place_changed", handlePlaceSelect);
  };

  function handlePlaceSelect() {
    let place = autocomplete.getPlace();

    if (!place.geometry) {
      setAddressErrors('Please enter a valid address');
      document.getElementById("autocomplete").value = "";
      return;
    } else {
      const res = checkDistance(place.formatted_address);
      res.then((result) => {
        if (result) {
          setCurrentUserAddress(place.formatted_address);
          setAddressVerified(true);
          setAddressErrors('');
        }
      });
    }
  }

  const checkDistance = async (userAddress) => {
    const geocoder = new google.maps.Geocoder();
  
    return new Promise((resolve, reject) => {
      geocoder.geocode({ address: userAddress }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const userLocation = results[0].geometry.location;
          const restaurantLocation = new google.maps.LatLng(34.0008736 , -84.1705513);
  
          // Calculate distance between user's location and restaurant's location
          const distanceInMeters = google.maps.geometry.spherical.computeDistanceBetween(userLocation, restaurantLocation);
          const distanceInMiles = distanceInMeters * 0.000621371; // Convert meters to miles
  
          // Check if the distance is within 10 miles
          if (distanceInMiles <= 8) {
            // Address is within 5 miles, proceed with delivery
            setAddressErrors('');
            setAddressVerified(true);
            resolve(true);
          } else {
            // Address is more than 5 miles away
            setAddressErrors('Sorry, we cannot deliver to places more than 8 miles away.');
            setAddressVerified(false);
            resolve(false);
          }
        } else {
          // Geocoding failed, show an error
          setAddressErrors('Unable to geocode the address. Please try again later.');
          setAddressVerified(false);
          resolve(false);
        }
      });
    });
  };
  
  const handleStoreMap = () => {
    /*global google*/ // To disable any eslint 'google not defined' errors
    const mapOptions = {
      center: new google.maps.LatLng(34.0008736 , -84.1705513),
      zoom: needDelivery ? 10 : 14,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      styles: [
        { elementType: "geometry", stylers: [{ color: "#f5f5f5" }] },
        { elementType: "labels.text.stroke", stylers: [{ color: "#ffffff" }] },
        { elementType: "labels.text.fill", stylers: [{ color: "#000000" }] },
        {
          featureType: "administrative.land_parcel",
          elementType: "labels.text.fill",
          stylers: [{ color: "#bdbdbd" }],
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [{ color: "#d6d6d6" }],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [{ color: "#737373" }],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [{ color: "#a9dfbf" }],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [{ color: "#6b9a76" }],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ color: "#ffffff" }],
        },
        {
          featureType: "road.arterial",
          elementType: "geometry",
          stylers: [{ color: "#fdfdfd" }],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.text.fill",
          stylers: [{ color: "#757575" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [{ color: "#eeeeee" }],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [{ color: "#616161" }],
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [{ color: "#e5e5e5" }],
        },
        {
          featureType: "transit.station",
          elementType: "labels.text.fill",
          stylers: [{ color: "#737373" }],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [{ color: "#b3cde0" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [{ color: "#515c6d" }],
        },
      ],
    };
    
    
    const map = new google.maps.Map(document.getElementById("gmp-map"), mapOptions);

    // Add marker
    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(34.0008736 , -84.1705513),
      title: "Ooka Sushi",
      map: map,
    });

    if (needDelivery) {
      // Add circle overlay
      new google.maps.Circle({
        strokeColor: "black",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "gray",
        fillOpacity: 0.35,
        map: map,
        center: new google.maps.LatLng(34.0008736 , -84.1705513),
        radius: 16093.44, // 10 miles in meters,
        
      });
    }
  };

  //handle coupon
  const checkCurrentCoupon = async (e) => {
    e.preventDefault();
    setCheckingCoupon(true);
    setCouponError('');
    try { 
       await dispatch(checkCouponThunk(promoCode));
    } catch (error) {
      setCheckingCoupon(false);
    } finally {
      setCheckingCoupon(false);
    }
  };

  const handleRemoveCoupon = () => {
    setPromoCode('');
    setCouponError('');
    dispatch(clearCoupon(''));
  };

  const handleEditCartModal = (item) => {
    setSelectedItem(item);
    setEditCartModal(true);
  };

  const calculateSubtotal = () => {
    let subtotal = cart.reduce(
      (total, cartItem) => total + cartItem.Item.price * cartItem.quantity,
      0
    );  

    cart.forEach((cartItem) => {
      cartItem.options.forEach((option) => {
        subtotal += option.priceModifier * cartItem.quantity;
      });
    });

    return subtotal.toFixed(2);


  };

  const calculateTaxes = () => {
    return (calculateSubtotal() * fee?.tax).toFixed(2);
  };

  const calculateDiscount = () => {
    const subtotal = parseFloat(calculateSubtotal());
    let discount = 0;

    if (checkCoupon && subtotal >= parseFloat(checkCoupon.min_require)) {
      if (checkCoupon.discount_type === 'Percentage') {
        discount = (subtotal * (parseFloat(checkCoupon.discount) / 100)).toFixed(2);
      } else {
        discount = parseFloat(checkCoupon.discount);
      }
    }

    return discount;
  };

  const calculateServiceFee = () => {
    const subtotal = parseFloat(calculateSubtotal());
    return (subtotal * fee?.service_fee + parseFloat(fee?.commission_fee))?.toFixed(2);
  };

  const calculateTip = () => {
    const subtotal = parseFloat(calculateSubtotal());
    return ((subtotal * tip) / 100).toFixed(2);
  };

  const calculateTotal = () => {
    const subtotal = parseFloat(calculateSubtotal());
    const taxes = parseFloat(calculateTaxes());
    const discount = calculateDiscount();
    const commissionFee = parseFloat(fee?.commission_fee);
    const serviceFee = orderMethod === 'delivery' ? parseFloat(calculateServiceFee()) : 0;
    const deliveryFee = orderMethod === 'delivery' ? parseFloat(fee?.delivery_fee) : 0;
    const tipAmount = parseFloat(customTip || calculateTip());

    let total = (subtotal + taxes - discount + serviceFee + deliveryFee + tipAmount).toFixed(2);
    if (orderMethod === 'pickup') {
      total = (parseFloat(total) + commissionFee).toFixed(2);
    }
    return total;
  };

  useEffect(() => { 
    if(checkCoupon?.min_require && parseFloat(calculateSubtotal()) < parseFloat(checkCoupon.min_require)){
      setCouponError('Minimum order amount of $' + checkCoupon.min_require + ' is required to use this coupon.');
    }
  },[checkCoupon, calculateSubtotal()])

  const handleTipSelect =  (percentage) => {
    setTip(percentage)
    setCustomTip(0)
 };

 const handleCustomTip = () => {
  setTip(0);
   setShowTipModal(true);
 };

 const incrementTip = () => setCustomTip(prevTip => +prevTip + 1);
 const decrementTip = () => setCustomTip(prevTip => +Math.max(0, prevTip - 1));


 const handleCustomTipChange = (e) => {
   if (/^\d*$/.test(e.target.value)) { // Allow only digits
     setCustomTip(Number(e.target.value));
   }
   setTip(0); // Reset tip percentage
 };


  const handleEditFreeItem = (item) => {
    setSelectedItem(item);
    setEditFreeItem(true);
  };
  
  const needAmountReached = () => {
    // Iterate through freeItems array
    const freeItemed = JSON.parse(localStorage.getItem('freeItem'));

    if(freeItemed?.Item){
    for (const freeItem of freeItems) {
      // Find the matching ReedemableItem based on item 
      for(const item of freeItem.ReedemableItems){ 
        if(item?.Item?.id === freeItemed?.Item?.id){
          return +freeItem.need_amount <= +calculateSubtotal();
        }
      }
    }} else { 
      return true;
    }
  };

  const handlePlaceOrder = () => {
    if(!isStoreOpen()){ 
      showTimeError();
      return
    }

    const lunchitem = cart.filter(item => item.Item.name.toLowerCase().includes('lunch'));
    

    if(!checkIfLunchTime() && lunchitem.length > 0){ 
      Swal.fire({ 
        icon: 'error',
        title: 'Lunch items ends at 3:00PM, Please remove lunch items from cart to proceed.',
      })
      return
    }



    setShowPaymentModal(true);
  };

  const handlePaymentMethodChange = (selectedOption) => {
    setPaymentOption(selectedOption);
  }

  const handleContinueCheckout = () => {
    if(!paymentOption){ 
      setError('Please select a payment method.');
      return
    }


    if(!name || !phone){
      setError('Please enter your name and phone number.');
      return;
    }

    //only allow numbers for phone, and length of 10
    if(!/^\d{10}$/.test(phone)){
      setError('Please enter a valid phone number. Only numbers are allowed.');
      return;
    }


    setActiveTab(true);
  }


  const handlePayInStore = async () => {
    setPayinStoreLoading(true);
    try {
      await dispatch(placeOrderThunkPayInStore(
        restaurantId,
        paymentOption,
        name,
        phone,
        customTip || calculateTip(),
        currentUserAddress || null,
        cart,
        calculateDiscount() > 0 ? checkCoupon?.coupon_id : null,
        freeItem?.Item ? freeItem : null
      ))
      .then(() => {

      setChangedCart(!changedCart);
      setPayinStoreLoading(false);
      
      navigate('/order-confirmed');
      })
    } catch (error) {
      setPayinStoreLoading(false);
      console.error('An error occurred:', error);
      setError('An error occurred. Please try again later.');
    }
  }
  

  const goBack = () => {
    setActiveTab(false);
  }

  return (
    <>
    <Header />
    <Container className="checkout-page" style={{marginTop:'80px'}}> 
      <Row>
        <Col md={7}>
          <div className="section pickup-details">

            <div className='d-flex row align-items-center justify-content-between'>
            <h5 className='col-5'>Checkout</h5>
            <div className='d-flex col-7 '>
              <Button 
                variant={orderMethod === 'pickup' ? "primary" : "outline-primary"} 
                className={`pickup-option  ${orderMethod === 'pickup' ? 'active' : ''}`} 
                onClick={() => {
                  setOrderMethod('pickup');
                  setNeedDelivery(false);
                }}
              >
                Pickup 
              </Button>
              {/* <Button 
                variant={orderMethod === 'delivery' ? "primary" : "outline-primary"} 
                className={`pickup-option  ${orderMethod === 'delivery' ? 'active' : ''}`} 
                onClick={() => {
                  setOrderMethod('delivery');
                  setNeedDelivery(true);
                }}
              >
                Delivery 
              </Button> */}
            </div>
            </div>
            
            
            <div className='gmp-map' id='gmp-map'></div>
            <p className="pickup-location text-center">Chefs House | Peking Duck<br />3580 Peachtree Industrial Blvd, Duluth, GA 30096
              <br />
              <a href="tel:+14702826718">+1(470) 282-6718</a>
              </p>
          </div>
          {orderMethod === 'delivery' && (
            <div className="section delivery-details">
              <h5>Delivery Details</h5>
              <Form>
                <Form.Group controlId="autocomplete" className="mb-3">
                  <Form.Label>Enter Delivery Address</Form.Label>
                  <Form.Control type="text" placeholder="Enter your address" />
                </Form.Group>
                {addressErrors && <p className="text-danger">{addressErrors}</p>}
              </Form>
            </div>
          )}
        </Col>
        <Col md={5}>
          <div className="section cart-summary">
            <h5 className="cart-summary-header" onClick={() => setCartOpen(!cartOpen)}>
              <FontAwesomeIcon icon={faCartShopping} className="toggle-icon" />
              Cart summary ({cart.length} item{cart.length !== 1 ? 's' : ''})
              <FontAwesomeIcon icon={cartOpen ? faChevronUp : faChevronDown} className="toggle-icon" />
            </h5>
            {cartOpen && (
              <>
              <div>

              {freeItem?.Item && (
                    <div
                      onClick={() => handleEditFreeItem({
                        id: freeItem?.Item.id,
                        name: freeItem?.Item?.name,
                        price: freeItem?.Item.price,
                        quantity: freeItem?.quantity,
                        special_instructions: freeItem?.special_instructions,
                        image_url: freeItem?.Item?.image_url,
                        Restaurant_Item_Attributes: freeItem?.Item?.Restaurant_Item_Attributes,
                        options: freeItem?.options,
                      })}
                      className="cart-item d-flex align-items-start mb-3"
                    >
                          <img 
                      src={freeItem.Item.image_url || 'https://theme-assets.getbento.com/sensei/3914814.sensei/assets/images/catering-item-placeholder-704x520.png'} 
                      alt={freeItem.Item.name} 
                      className="cart-item-image me-3" 
                    />
                    <div className="cart-item-details flex-grow-1">
                    <p className='item-name mb-1'>{language === 'en' ? freeItem?.Item?.name : freeItem?.Item?.chinese_name}</p>
                            {freeItem?.options?.map((attribute) => (
                              <p className="item-attribute small m-0" key={attribute.id}>
                                {getItemAttributeNameById(attribute.id, freeItem.Item)} {attribute?.priceModifier > 0 ? `+ $${attribute.priceModifier}` : null}
                              </p>
                            ))}
                            {freeItem?.special_instructions && (
                              <p className="item-instructions small m-0">
                                <strong>**</strong> {freeItem?.special_instructions}
                              </p>
                            )}
                          </div>
                          <div className="cart-item-quantity d-flex align-items-center justify-content-center">
                            <span className="item-quantity">1</span>
                            </div>
                            <p className="item-price ms-3">Free</p>
                            

                    </div>
                    
                  )  
                  }
              </div>

              <div>
                {cart.map((item, index) => (
                  <div key={index} className="cart-item d-flex align-items-start mb-3"
                    onClick={() => handleEditCartModal({
                      id: item?.Item.id,
                      name: item?.Item?.name,
                      price: item?.Item.price,
                      special_instructions: item?.special_instructions,
                      image_url: item?.Item?.image_url,
                      Restaurant_Item_Attributes: item?.Item?.Restaurant_Item_Attributes,
                      options: item?.options,
                      index: index,
                    })}
                  >
                    <img 
                      src={item.Item.image_url || 'https://theme-assets.getbento.com/sensei/3914814.sensei/assets/images/catering-item-placeholder-704x520.png'} 
                      alt={item.Item.name} 
                      className="cart-item-image me-3" 
                    />
                    <div className="cart-item-details flex-grow-1">
                      <p className="item-name mb-1">{language === 'en' ? item?.Item?.name : item?.Item?.chinese_name}</p>
                      {item?.options?.map((attribute, idx) => (
                        <p className="item-attribute small m-0" key={idx}>
                          {getItemAttributeNameById(attribute.id, item.Item)} {attribute?.priceModifier > 0 ? `+ $${attribute.priceModifier}` : null}
                        </p>
                      ))}
                      {item?.special_instructions && (
                        <p className="item-instructions small m-0">
                          <strong>**</strong> {item?.special_instructions}
                        </p>
                      )}
                    </div>
                    <div className="cart-item-quantity d-flex align-items-center justify-content-center">
                      <span className="item-quantity">{item.quantity}</span>
                    </div>
                    <p className="item-price ms-3">${item.price}</p>
                  </div>
                ))}
              </div>
              </>
            )}
          </div>
          <div className="section promotion">
            <h5>Promotion</h5>
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="Add promo code"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
              />
              <Button variant="outline-primary" onClick={(e) => checkCurrentCoupon(e)}>
                {checkingCoupon ? <span className="spinner-border spinner-border-sm"></span> : 'Apply'}
              </Button>
            </InputGroup>
            {checkCoupon && parseFloat(calculateSubtotal()) >= parseFloat(checkCoupon.min_require) && (
              <div className="mt-2">
                <p className="text-success">Coupon applied! Discount: ${calculateDiscount()}</p>
                <Button variant="link" onClick={handleRemoveCoupon}>Remove coupon</Button>
              </div>
            )}
            {checkCoupon && parseFloat(calculateSubtotal()) < parseFloat(checkCoupon.min_require) && (
              <div className="mt-2">
                <p className="text-danger">Minimum order amount of ${checkCoupon.min_require} is required to use this coupon.</p>
              </div>
            )}
            {checkCoupon && checkCoupon.message && (
              <div className="mt-2">
                <p className="text-danger">{checkCoupon.message}</p>
              </div>
            )}
            {!checkCoupon && (
              <div className="mt-2">
                <p className="text-danger">{couponError || 'Invalid coupon code.'}</p>
              </div>
            )}
          </div>
        
          <div className="section order-total">
            <h5>Order total</h5>
            <div className="order-summary">
              <p>Subtotal <span>${calculateSubtotal()}</span></p>
              <p>Taxes <span>${calculateTaxes()}</span></p>
              {orderMethod === 'pickup' && (
              <p>Commission Fee <span>${parseFloat(fee?.commission_fee).toFixed(2)}</span></p>
              )}
              {checkCoupon && parseFloat(calculateSubtotal()) >= parseFloat(checkCoupon.min_require) && (
                <p>Discount <span>-${calculateDiscount()}</span></p>
              )}
              {orderMethod === 'delivery' && (
                <>
                  <p>Service Fee <span>${calculateServiceFee()}</span></p>
                  <p>Delivery Fee <span>${fee?.delivery_fee}</span></p>
                </>
              )}
              <p>Tip <span>${parseFloat(customTip || calculateTip()).toFixed(2)}</span></p>
              <div className="tip-options-container">
                  <Button 
                    variant={tip === 10 ? "primary" : "outline-primary"} 
                    className={`tip-option custom-button ${tip === 10 ? 'active' : ''}`} 
                    onClick={() => handleTipSelect(10)}
                  >
                    10%
                  </Button>
                  <Button 
                    variant={tip === 15 ? "primary" : "outline-primary"} 
                    className={`tip-option custom-button ${tip === 15 ? 'active' : ''}`} 
                    onClick={() => handleTipSelect(15)}
                  >
                    15%
                  </Button>
                  <Button 
                    variant={tip === 18 ? "primary" : "outline-primary"} 
                    className={`tip-option custom-button ${tip === 18 ? 'active' : ''}`} 
                    onClick={() => handleTipSelect(18)}
                  >
                    18%
                  </Button>
                  <Button 
                    variant="outline-primary" 
                    className="tip-option " 
                    onClick={handleCustomTip}
                  >
                    Custom
                  </Button>
                </div>
              <p>Total <span>${calculateTotal()}</span></p>
            </div>
          </div>
            <p className="text-danger">{!needAmountReached() && 'You need to reach the minimum amount to redeem the free item.'}</p>
            {orderMethod === 'delivery' && calculateSubtotal() < 20 && <p className="text-danger">Minimum order amount of $20 in subtotal is required for delivery.</p>}
            {orderMethod === 'delivery' && !addressVerified && <p className="text-danger">Please enter a valid address within 8 miles of the restaurant.</p>}
            {isStoreOpen() === false && <p className="text-danger">Sorry, we are currently closed. Refer to our store hours.</p>}
            {/* <p className='text-danger'>Sorry online ordering currently close at this time!</p> */}
          <button className="place-order-btn"
          disabled={
            orderMethod === 'delivery' && !addressVerified
            || !needAmountReached()
            || orderMethod === 'delivery' && calculateSubtotal() < 20
            || isStoreOpen() === false
            // || true
          }
          
          onClick={handlePlaceOrder}
          >Place Order</button>
          
        </Col>
      </Row>
      <EditCartModal modal={editCartModal} item={selectedItem} setModal={setEditCartModal} />
      <EditFreeItemModal  modal={editFreeItem} item={selectedItem} setModal={setEditFreeItem} />
      <Modal show={showTipModal} onHide={() => setShowTipModal(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Enter Custom Tip</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="d-flex align-items-center justify-content-center">
          <button className="btn btn-outline-secondary" onClick={decrementTip}>
            <FontAwesomeIcon icon={faChevronDown} />
          </button>
          <Form.Control
            type="tel"
            min="0"
            value={customTip}
            onChange={handleCustomTipChange}
            placeholder="Enter custom tip amount"
            className="mx-2 text-center"
            style={{ maxWidth: '100px' }}
          />
          <button className="btn btn-outline-secondary" onClick={incrementTip}>
            <FontAwesomeIcon icon={faChevronUp} />
          </button>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <button className="w-100 btn btn-dark" onClick={() => setShowTipModal(false)}>Save</button>
      </Modal.Footer>
    </Modal>
      <Modal show={showPaymentModal} onHide={() => setShowPaymentModal(false)} backdropClassName="overlay" centered size="lg">

        
        <>
          <Modal.Header closeButton>
            <Modal.Title>Payment Method</Modal.Title>
          </Modal.Header>
          <Modal.Body>
  {paymentSubmitLoading ? (
    <Lottie 
      loop
      animationData={loadingJson}
      play
    />
  ) : (
    <>
      <div className="d-flex">
        {needDelivery ? (
         <button
         className={`payment-button ${paymentOption === 'online' ? 'primary' : 'outline-primary'}`}
           onClick={() => handlePaymentMethodChange('online')}
         >
           Online Payment
         </button>
        ) : (
          <>
            <button
         className={`payment-button  w-100 ${paymentOption === 'online' ? 'primary' : 'outline-primary'}`}
         onClick={() => handlePaymentMethodChange('online')}
            >
              Online Payment
            </button>
            {/* <button
         className={`payment-button ${paymentOption === 'in-store' ? 'primary' : 'outline-primary'}`}
         onClick={() => handlePaymentMethodChange('in-store')}
            >
              In Store
            </button> */}
          </>
        )}
      </div>

      <div>
        {error && <p className="text-danger">{error}</p>}
        <div className="row mb-2">
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-3">
            <input
              type="text"
              className="form-control"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
              style={{ textAlign: 'center' }}
              required
              disabled={activeTab}
              maxLength={20}
            />
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-3">
            <input
              type="tel"
              className="form-control"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Phone (10 digits, no dashes or spaces)"
              style={{ textAlign: 'center' }}
              required
              disabled={activeTab}
              maxLength={10}
            />
          </div>
          {!activeTab ? (
            <button className="text-center w-100 mt-4 black-button" onClick={handleContinueCheckout}>
              Continue
            </button>
          ) : null}
          {activeTab && (
            <>
            <div className='d-flex justify-content-center align-items-center ' onClick={goBack}
                            style={{ fontSize: '20px', cursor: 'pointer', gap: '10px', color: 'black' }}

            >
              <FontAwesomeIcon
                icon={faArrowLeft}
              />
              <p className="text-center mt-3">Back</p>
              </div>
              {paymentOption === 'online' && (
                   <>
                   <CheckoutApp
                   tip={customTip || calculateTip()}
                   restaurantId={restaurantId}
                   name={name}
                   phone={phone}
                   cartItems={cart}
                   coupon={calculateDiscount() > 0 ? checkCoupon?.coupon_id : null}
                   address={'Pickup'}
                   />   
                 </>
              )}
          
              {paymentOption === 'in-store' && (
                <>
                  <button className="black-button p-3 mt-3" onClick={handlePayInStore} disabled={payinStoreLoading}>
                    {payinStoreLoading ? (
                      <span className="spinner-border spinner-border-sm"></span>
                    ) : (
                      'Place Order'
                    )}
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )}
</Modal.Body>

        </>
      </Modal>
    </Container>
    </>
  );
};

export default CheckoutPage;
