import "swiper/css/effect-fade";
import "swiper/css/free-mode";
import "swiper/css/grid";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";
import "swiper/css";
import "react-modal-video/css/modal-video.css";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "./assets/css/common.css";
import "rsuite/dist/rsuite.min.css";
import "./assets/other/switcher/switcher.css";
import "./assets/css/style.css";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import ScrollTop from "./constent/ScrollTop";
import AboutUs from "./pages/AboutUs";
import Faq from "./pages/Faq";

import ComingSoon from "./pages/ComingSoon";
import MenuStyle4 from "./pages/MenuStyle4";

import ContactUs from "./pages/ContactUs";
import OnlineOrder from "./pages/OnlineOrder";
import { useDispatch, useSelector } from "react-redux";
import { getRestaurantThunk } from "./store/restaurant";
import Home2 from "./pages/Home2";
import { useEffect } from "react";
import Order from "./pages/Order";
import CheckoutPage from "./pages/ShopCheckout";


function App() {
  const dispatch = useDispatch()
  const restaurant = useSelector((state) => state.restaurants.restaurant);

  useEffect(() => {
    // Dispatch API call action only once when the component mounts
    dispatch(getRestaurantThunk(1));
  }, [dispatch]); // Empty dependency array ensures this effect runs only once


  return (
    <>
      <div className="page-wraper">
        <Router>
          <ScrollTop />
        
          <Routes>
              <Route exact path="/" element={<Home2 restaurant={restaurant} />} />
              <Route path="/online-order" element={<OnlineOrder restaurant={restaurant}/>} />
              <Route path="/menu" element={<MenuStyle4 restaurant={restaurant}/>} />

              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/faq" element={<Faq />} />

              <Route path="/checkout" element={<CheckoutPage fee={restaurant?.Restaurant_Fees[0]}/>} />

              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/order-confirmed" element={<ComingSoon />} />
              <Route path='/order/:id/:restaurant_id' element={<Order />} />

            {/* <Route path="/account" element={<Account />} /> */}
            </Routes>
            
          {/* <Switcher />// */}
        </Router>
      </div>
    </>
  );
}

export default App;
