import { getToken } from "./utlits";

const CREATE_CHECKOUT_SESSION = "stripe/CREATE_CHECKOUT_SESSION";
const FETCH_ORDER_ID = "stripe/FETCH_ORDER_ID";

const createCheckoutSession = (session) => ({
    type: CREATE_CHECKOUT_SESSION,
    session
});

const fetchOrderId = (orderId) => ({
    type: FETCH_ORDER_ID,
    orderId
});


export const createCheckoutSessionThunk = (tip,restaurantId, cartItems, coupon, address, name, phone) => async (dispatch) => {
    const token = getToken()

    const response = await fetch(`https://hutaoadmin.onrender.com/api/stripe/create-payment-intent`, {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            credentials: 'include',
            'Authorization': `Bearer ${token}`,
        },
        credentials: 'include',
        body: JSON.stringify({tip, restaurantId, cartItems, coupon, address, name, phone})
    })
    if (response.ok) {
        const data = await response.json();
        dispatch(createCheckoutSession(data))
    }
}
export const fetchOrderDataThunk = (paymentIntentId) => async (dispatch) => {
    let attempts = 0;
    let orderData = null;

    while (attempts < 3 && !orderData) {  // ✅ Retry up to 3 times
        try {
            const response = await fetch(`https://hutaoadmin.onrender.com/api/orders/webhook-response/${paymentIntentId}`);
            if (response.ok) {
                const data = await response.json();
    
                if (data) {
                    dispatch(fetchOrderId(data));  // ✅ Store full order data in Redux
                    return data;
                }
            }
        } catch (error) {
            console.error("❌ Error fetching order data:", error);
        }

        attempts++;
        await new Promise(resolve => setTimeout(resolve, 3000)); // ✅ Wait 3 sec before retrying
    }

    console.error("❌ Order data not found after multiple attempts.");
    return null;
};

const initialState = {
    data: null,
    orderId: null
};

const stripeReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_CHECKOUT_SESSION:
            return {
                ...state,
                data: action.session
            };
        case FETCH_ORDER_ID:
            return {
                ...state,
                orderId: action.orderId
            };
        default:
            return state;
    }
};


export default stripeReducer;
